<template>
  <div id="owe_map_border_compo"
       style="height: 400px;">
  </div>
</template>

<script>
export default {
  name: "OweMapBorderCompo",
  props: {
    address: {
      type: String
    }
  },
  watch: {
    address: {
      handler(val) {
        if (undefined !== val) {
          this.initMap(val);
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    initMap(val) {
      if (document.getElementById('owe_map_border_compo') && 0 === document.getElementById('owe_map_border_compo').children.length) {
        let geo = new window.qq.maps.Geocoder()
        geo.getLocation(val)

        geo.setComplete(function (res) {
          let map = new window.qq.maps.Map(document.getElementById('owe_map_border_compo'), {
            center: res.detail.location,//将经纬度加到center属性上
            zoom: 16,//缩放
            draggable: true,//是否可拖拽
            scrollwheel: true,//是否可滚动缩放
            disableDoubleClickZoom: false
          })

          new window.qq.maps.Marker({
            position: res.detail.location,//标记的经纬度
            animation: window.qq.maps.MarkerAnimation.BOUNCE,//标记的动画
            map: map//标记的地图
          })

          new window.qq.maps.CityService({
            map: map
          });
        });
        //若服务请求失败，则运行以下函数
        geo.setError(function () {
          geo.getLocation("东莞市")
          geo.setComplete(function (res) {
            let map = new window.qq.maps.Map(document.getElementById('owe_map_border_compo'), {
              center: res.detail.location,//将经纬度加到center属性上
              zoom: 16,//缩放
              draggable: true,//是否可拖拽
              scrollwheel: true,//是否可滚动缩放
              disableDoubleClickZoom: false
            })

            new window.qq.maps.Marker({
              position: res.detail.location,//标记的经纬度
              animation: window.qq.maps.MarkerAnimation.BOUNCE,//标记的动画
              map: map//标记的地图
            })

            new window.qq.maps.CityService({
              map: map
            });
          });
        });
      }
    }
  }
};
</script>
<style>
#owe_map_border_compo div div:nth-child(2) div {
  display: none !important;
}
</style>