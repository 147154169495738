<template>
  <div id="pid">
    <div class="pid_wrap">
      <div class="pid_column_warp">
        网站首页/企业项目/项目详情
      </div>
      <!--  基础信息    -->
      <div class="pid_base_warp">
        <div class="pid_display_warp">
          <el-carousel trigger="click" height="440px" indicator-position="outside" :autoplay="'1' === baseInfo.carousel" :interval="this.baseInfo.timeInterval * 1000">
            <el-carousel-item v-for="item in projectData.previewList" :key="item">
              <el-image v-show="item" style="width:100%; height:100%" :src="preview+item" fit="cover"/>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="pid_info_warp">
          <div class="pid_info_title_warp">
            {{ projectData.itemName }}
          </div>
          <div class="pid_price_warp">
            ￥{{ projectData.priceBottom }}-{{ projectData.priceTop }}<span>/月起</span>
          </div>
          <div class="pid_multiple_warp">
            <div :title="projectData.areaBottom+'-'+projectData.areaTop+' ㎡'">
              面积：{{ projectData.areaBottom }}-{{ projectData.areaTop }}㎡
            </div>
            <div :title="projectData.bwop">
              按押方式：{{ projectData.bwop }}
            </div>
            <div :title="projectData.propertyFee">
              物业费：{{ projectData.propertyFee }}
            </div>
          </div>
          <div class="pid_multiple_warp">
            <div :title="projectData.houseType">
              户型规格：{{ projectData.houseType }}
            </div>
            <div>
              租赁类型：{{ projectData.leaseType }}
            </div>
            <div :title="'每月'+projectData.payRentTime+'日 - 月底前'">
              缴租日期：每月{{ projectData.payRentTime }}日 - 月底前
            </div>
          </div>
          <div class="pid_multiple_warp">
            <div :title="projectData.address">
              地址：{{ projectData.address }}
            </div>
          </div>
          <div class="pid_label_warp" v-if="projectData.itemLabelList && 0!==projectData.itemLabelList.length">
            <div class="label_item" v-for="(item, index) in projectData.itemLabelList" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="pid_phone_warp">
            预约电话：{{ projectData.phone }}
          </div>
          <div class="pid_book_now_warp">
            <div class="book_now_button_warp" @click="bookNowButtonClick">
              立即预约
            </div>
          </div>
        </div>
      </div>
      <!--  基础设施    -->
      <div class="pid_fac_warp">
        <div class="fac_title_warp">
          <div style="width: 10%;border-bottom: 2px solid red;">
            <el-image style="width: 20px;height: 20px;margin-right: 10px;"
                      src="./statics/icon/pages/projectDetails/facilities.png" fit="cover"></el-image>
            基础设施
          </div>
          <div style="width: 90%;border-bottom: 2px solid #f0f0f0;">
          </div>
        </div>
        <div class="fac_list_warp">
          <div class="list_item_warp" v-for="item in projectData.baseFacList" :key="item.fileId">
            <div class="list_item_img">
              <el-image style="width: 50px;height: 50px;" :src="preview+item.fileId" fit="cover"></el-image>
            </div>
            <div class="label_item_txt">
              {{ item.facName }}
            </div>
          </div>
        </div>
      </div>
      <!--  配套设施    -->
      <div class="pid_fac_warp">
        <div class="fac_title_warp">
          <div style="width: 10%;border-bottom: 2px solid red;">
            <el-image style="width: 20px;height: 20px;margin-right: 10px;"
                      src="./statics/icon/pages/projectDetails/facilities.png" fit="cover"></el-image>
            配套设施
          </div>
          <div style="width: 90%;border-bottom: 2px solid #f0f0f0;">
          </div>
        </div>
        <div class="fac_list_warp">
          <div class="list_item_warp" v-for="item in projectData.matchingFacList" :key="item.fileId">
            <div class="list_item_img">
              <el-image style="width: 50px;height: 50px;" :src="preview+item.fileId" fit="cover"></el-image>
            </div>
            <div class="label_item_txt">
              {{ item.facName }}
            </div>
          </div>
        </div>
      </div>
      <!--  户型简介    -->
      <div class="pid_synopsis_warp">
        <div class="synopsis_title_warp">
          <div style="width: 10%;border-bottom: 2px solid red;">
            <el-image style="width: 20px;height: 20px;margin-right: 10px;"
                      src="./statics/icon/pages/projectDetails/miaoshu.png" fit="cover"></el-image>
            户型简介
          </div>
          <div style="width: 90%;border-bottom: 2px solid #f0f0f0;">
          </div>
        </div>
        <div class="synopsis_content_warp">
          {{ projectData.synopsis }}
        </div>
      </div>
      <!--  地图    -->
      <div class="pid_map_warp">
        <div class="map_title_warp">
          <div style="width: 10%;border-bottom: 2px solid red;">
            <el-image style="width: 20px;height: 20px;margin-right: 10px;"
                      src="./statics/icon/pages/projectDetails/miaoshu.png" fit="cover"></el-image>
            地图查看
          </div>
          <div style="width: 90%;border-bottom: 2px solid #f0f0f0;">
          </div>
        </div>
        <div class="map_content_warp">
          <OweMapBorderCompo
              :address="projectData.address"
          />
        </div>
      </div>
      <!--  相关推荐    -->
      <div class="pid_relevant_warp" v-show="project.total">
        <div class="relevant_title_warp">
          <div style="width: 10%;border-bottom: 2px solid red;">
            <el-image style="width: 20px;height: 20px;margin-right: 10px;"
                      src="./statics/icon/pages/projectDetails/huxing.png" fit="cover"></el-image>
            相关推荐
          </div>
          <div style="width: 90%;border-bottom: 2px solid #f0f0f0;">
          </div>
        </div>
        <div class="relevant_content_warp">
          <div class="relevant_content_item">
            <div class="project_info_item" v-for="item in project.data" :key="item.id">
              <el-link @click="pidClick(item.id)" :underline="false">
                <el-card class="box-card" shadow="hover" style="width: 380px; height: 415px;">
                  <div class="project_info_item_img">
                    <el-image style="width:100%; height:100%" :src="preview+item.fileId" fit="cover"/>
                    <p>{{ item.itemName }}</p>
                  </div>
                  <div class="project_info_item_summary">{{ item.synopsis }}</div>
                  <div class="project_info_link">
                    <div class="project_info_link_left">{{ item.createTime }}</div>
                    <div class="project_info_link_right">了解详情</div>
                  </div>
                </el-card>
              </el-link>
            </div>
          </div>
          <div class="relevant_content_page">
            <el-pagination
                @current-change="handleCurrentChange"
                :hide-on-single-page="true"
                :page-size="3"
                layout="prev, pager, next, jumper"
                :total="project.total">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="gotop" v-show="gotop" @click="toTop">
          <el-image style="height: 50px;width: 50px" class="copyright_img" src="./statics/icon/pages/icon/top.png"
                    fit="fill"/>
        </div>
      </div>
    </div>
    <el-dialog title="安居建设小程序" :visible.sync="dialogVisible" width="30%">
      <div style="width: 100%;text-align: center;">
        <el-image :src="xcxUrl"></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import OweMapBorderCompo from "@/components/OweMapBorderCompo";

export default {
  name: 'projectDetails',
  components: {
    OweMapBorderCompo
  },
  props: {},
  watch: {
    $route: {
      handler(val) {
        this.id = val.query.id;
        this.getInfo()
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      xcxUrl:"./statics/icon/xcx/xcx.jpg",
      dialogVisible: false,
      gotop: false,
      //人才安居
      project: {
        //人才安居数据
        data: [],
        //人才安居总数
        total: 0,
      },
      //主键
      id: "",
      //文章数据
      projectData: {},
      //联系方式
      baseInfo: {}
    }
  },
  created() {
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //获取官网基本信息
    getInfo() {
      this.request.get("/baseinfo/getInfo?key=" + this.config.key).then((res) => {
        this.baseInfo = res.data
      })
    },
    handleScroll() {
      let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {

      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    //翻页
    handleCurrentChange(val) {
      this.getProjects(val);
    },
    //人才安居详情点击
    pidClick(id) {
      this.$router.push({path: "/pid", query: {"id": id}});
    },
    //立即预约点击时间
    bookNowButtonClick() {
      this.dialogVisible = true;
    },
    //项目推荐轮播列表
    getProjects(index) {
      let _index = (undefined === index) ? 1 : (index);
      this.request.get('/item/relevantList?key=' + this.config.key + "&id=" + this.id + "&areaTop=" + this.projectData.areaTop + "&areaBottom=" + this.projectData.areaBottom + "&pageNum=" + _index + "&pageSize=3").then(res => {
        this.project.total = res.total;
        this.project.data = res.data;
      });
    },
    //获取项目数据
    getData() {
      this.request.get('/item/getInfo?key=' + this.config.key + "&id=" + this.id).then(res => {
        this.projectData = (undefined === res.data) ? {} : res.data;
        this.getProjects(0);
      });
    }
  }
}
</script>
<style>
@import "../../assets/styles/pages/projectDetails.css";
</style>
